@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

/* .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
} */

/* .site-layout .site-layout-background {
  background: #fff;
} */

.panelLayout {
  height: 100%;
}

/* 
.logoutButton {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  margin-right: 16px;
}

.customHeader {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
