/* Navigation Panel CSS */

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  height: 92vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Header CSS */

.logotext {
  color: white;
  background-color: #007bff;
  font-size: 23px;
  border-right: 1px solid white;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.customheader {
  height: 56px;
  color: white;
  background-color: #007bff;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoutbutton {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  margin-right: 16px;
}

/* Slider CSS */

.sliderchild {
  background-color: white;
}

/* Footer CSS */

.customfooter {
  text-align: center;
}

/* Content CSS */

.customcontent {
  padding: 15px;
  overflow-y: auto;
}

/* Loader CSS */

.loaderspin {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-nested-loading > .ant-spin-container {
  height: 100%;
}

/* Card CSS */

.card {
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
  margin-bottom: 1rem;
}

.card-primary.card-outline {
  border-top: 3px solid #007bff;
}

.card-header {
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.text-center {
  text-align: center !important;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  background-color: white;
}

.cardheadetitle {
  font-size: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.cardheadeitemcontainer {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardheadersubtext {
  font-size: 22px;
  font-weight: 600;
}

.cardoverflow {
  overflow: overlay;
}

.cardheaderbuttonspace {
  margin-right: 5px;
}

.cardheaderspanheader {
  font-size: 14px;
}

/* Grid CSS */

.rowverticalspace {
  padding-top: 8px;
}

/* Modal CSS */

.readonlymodal {
  pointer-events: none;
}

/* TextBox CSS */

.readonlytextbox {
  opacity: 1;
  pointer-events: none;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.center {
  align-items: center;
  justify-content: center;
}

.flex {
  flex: auto;
}

.instance {
  margin-right: 20px;
  font-size: 18px;
}
